.gold-text {
  background-image: repeating-linear-gradient(
    to right,
    #a2682a 0%,
    #be8c3c 8%,
    #be8c3c 18%,
    #d3b15f 27%,
    #faf0a0 35%,
    #ffffc2 40%,
    #faf0a0 50%,
    #d3b15f 58%,
    #be8c3c 67%,
    #b17b32 77%,
    #bb8332 83%,
    #d4a245 88%,
    #e1b453 93%,
    #a4692a 100%
  );
  background-size: 250%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: drop-shadow(0 0 1px rgba(255, 200, 0, 0.3));
  animation: MoveBackgroundPosition 6s ease-in infinite;
}

@keyframes MoveBackgroundPosition {
  0%,
  100% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  transition: width 0.6s ease;
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
